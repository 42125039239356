<template>
  <b-row class="bg-black-0 rounded p-3 mx-0">
    <b-col cols="12">
      <h2>Detail Mobil Unit</h2>
    </b-col>
    <b-col cols="6">
      <ul>
        <li>
          <p class="text-black-60">Nama Mobil Unit</p>
          <p>{{ currentDataBloodvan.name }}</p>
        </li>
        <li>
          <p class="text-black-60">Lokasi Mobil Unit</p>
          <p>{{ currentDataBloodvan.address }}</p>
          <iframe
            :src="`https://maps.google.com/maps?q=${mapCoordinates}&hl=es&z=14&amp;output=embed`"
            width="400"
            height="300"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          />
        </li>
        <li>
          <p class="text-black-60">Status</p>
          <b-badge
            :variant="
              currentDataBloodvan.is_active === 1 ? 'success' : 'danger'
            "
            size="lg"
          >
            {{ currentDataBloodvan.is_active === 1 ? "Aktif" : "Non Aktif" }}
          </b-badge>
        </li>
      </ul>
    </b-col>
    <b-col cols="6">
      <ul>
        <li>
          <p class="text-black-60">Layanan</p>
          <p>{{ currentDataBloodvan.service }}</p>
        </li>
        <li>
          <p class="text-black-60">Kontak Mobil Unit</p>
          <p>{{ currentDataBloodvan.contact }}</p>
        </li>
        <li>
          <p class="text-black-60">Jadwal Operasional</p>
          <div
            v-for="(singleSchedule, index) in parsedSchedule"
            :key="index"
            class="d-flex align-items-center justify-content-around"
          >
            <p>
              {{
                singleSchedule.day
                  ? formatDay(singleSchedule.day)
                  : formatDate(singleSchedule.date)
              }}
            </p>
            <ul class="d-flex">
              <li>
                {{
                  singleSchedule.open_hour + ":" + singleSchedule.open_minute
                }}
              </li>
              -
              <li>
                {{
                  singleSchedule.close_hour + ":" + singleSchedule.close_minute
                }}
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow, BButton, BBadge } from "bootstrap-vue";
import BloodvanService from "../../../../api/managebloodvan/manageBloodvanAPI";

export default {
  name: "ViewBloodvan",
  components: {
    BRow,
    BCol,
    BButton,
    BBadge,
  },
  data() {
    return {
      currentDataBloodvan: null,
      parsedSchedule: [],
    };
  },
  computed: {
    mapCoordinates() {
      if (
        this.currentDataBloodvan &&
        this.currentDataBloodvan.latitude &&
        this.currentDataBloodvan.longitude
      ) {
        return `${this.currentDataBloodvan.latitude},${this.currentDataBloodvan.longitude}`;
      }
      return "-6.914744,107.609810"; // Koordinat default (pusat Kota Bandung)
    },
  },
  created() {
    this.getBloodvan(this.$route.params.id);
  },
  methods: {
    getBloodvan(id) {
      BloodvanService.getBloodvanDetail(id)
        .then((response) => {
          this.currentDataBloodvan = response.data.data;
          this.parseSchedule(this.currentDataBloodvan.schedule);
        })
        .catch((e) => {
          console.error("Error fetching bloodvan data:", e);
          // Tambahkan penanganan error di sini, misalnya menampilkan pesan error ke pengguna
        });
    },
    parseSchedule(scheduleString) {
      try {
        const scheduleData = JSON.parse(scheduleString);
        if (Array.isArray(scheduleData)) {
          if (scheduleData[0].hasOwnProperty("day")) {
            // Format jadwal harian
            this.parsedSchedule = scheduleData.sort(
              (a, b) => this.getDayNumber(a.day) - this.getDayNumber(b.day)
            );
          } else if (scheduleData[0].hasOwnProperty("date")) {
            // Format jadwal berdasarkan tanggal
            this.parsedSchedule = scheduleData.sort(
              (a, b) => new Date(a.date) - new Date(b.date)
            );
          }
        } else {
          console.error("Invalid schedule data format");
          this.parsedSchedule = [];
        }
      } catch (error) {
        console.error("Error parsing schedule:", error);
        this.parsedSchedule = [];
      }
    },
    formatDay(day) {
      const days = {
        monday: "Senin",
        tuesday: "Selasa",
        wednesday: "Rabu",
        thursday: "Kamis",
        friday: "Jumat",
        saturday: "Sabtu",
        sunday: "Minggu",
      };
      return days[day.toLowerCase()] || day;
    },
    getDayNumber(day) {
      const days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      return days.indexOf(day.toLowerCase());
    },
    formatDate(dateString) {
      try {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
          throw new Error("Invalid date");
        }
        const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        return date.toLocaleDateString("id-ID", options);
      } catch (error) {
        console.error("Error formatting date:", error);
        return dateString; // Return the original string if formatting fails
      }
    },
  },
};
</script>
